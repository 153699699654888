<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect
      class="a"
      width="24"
      height="24"
    />

    <g transform="translate(3.993 3.993)">
      <path
        class="b"
        d="M680.276-579.3l-1.863-1.863a.891.891,0,0,1-.18-1c.011-.024.016-.055,0-.067l-.314-.314a.054.054,0,0,0-.039-.016.054.054,0,0,0-.039.016.882.882,0,0,1-.627.26.882.882,0,0,1-.627-.26l-1.944-1.944a3.987,3.987,0,0,1-1.416-2.865,2.056,2.056,0,0,1,.378-1.167.259.259,0,0,1,.188-.1.26.26,0,0,1,.2.076l7.76,7.76a1.046,1.046,0,0,1,0,1.477,1.041,1.041,0,0,1-.738.3,1.041,1.041,0,0,1-.739-.3Zm-1.986-3.608.314.314a.571.571,0,0,1,.1.649.371.371,0,0,0,.075.415l1.863,1.863a.527.527,0,0,0,.744,0,.527.527,0,0,0,0-.744l-7.518-7.518c-.2.477-.359,1.573,1.145,3.077l1.944,1.944a.367.367,0,0,0,.261.108.367.367,0,0,0,.261-.108.568.568,0,0,1,.405-.168.57.57,0,0,1,.406.164Zm-4.984,3.585a1.036,1.036,0,0,1-.306-.738,1.037,1.037,0,0,1,.306-.738l2-2a.259.259,0,0,1,.366,0,.259.259,0,0,1,0,.366l-2,2a.524.524,0,0,0-.154.372.523.523,0,0,0,.154.372.523.523,0,0,0,.372.154.523.523,0,0,0,.372-.154l2.072-2.072a.259.259,0,0,1,.366,0,.259.259,0,0,1,0,.366l-2.072,2.072a1.038,1.038,0,0,1-.738.306,1.038,1.038,0,0,1-.738-.31ZM679-584.354a.259.259,0,0,1,0-.366l.543-.543a.261.261,0,0,1,.187-.076l.609.009a.8.8,0,0,0,.552-.251l1.685-1.685a.259.259,0,0,1,.366,0,.259.259,0,0,1,0,.366l-1.685,1.685a1.305,1.305,0,0,1-.926.4l-.5-.008-.465.465a.258.258,0,0,1-.183.076.258.258,0,0,1-.183-.072Zm-.666-.666a.259.259,0,0,1,0-.366l.47-.47.012-.482a1.355,1.355,0,0,1,.418-.9l1.685-1.685a.259.259,0,0,1,.366,0,.259.259,0,0,1,0,.366l-1.685,1.685a.848.848,0,0,0-.267.547l-.014.586a.259.259,0,0,1-.076.177l-.543.543a.258.258,0,0,1-.183.076.258.258,0,0,1-.184-.077Zm1.635-.969a.259.259,0,0,1,0-.366l1.653-1.653a.259.259,0,0,1,.366,0,.259.259,0,0,1,0,.366l-1.653,1.653a.258.258,0,0,1-.183.076.258.258,0,0,1-.184-.076Z"
        transform="translate(-669.679 591.938)"
      />

      <path
        class="c"
        d="M13.657,2.343a8,8,0,1,0,0,11.314,8,8,0,0,0,0-11.314ZM13.29,13.29a7.482,7.482,0,1,1,0-10.581,7.481,7.481,0,0,1,0,10.581Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Meal',
};
</script>

<style lang="scss" scoped>
.a {
  fill:none;
}

.b,.c {
  fill: currentColor;
  stroke: currentColor;
}

.b {
  stroke-width: 0.2px;
}

.c {
  stroke-width: 0.5px;
}
</style>
